<template>
    <localization :language="'ru-RU'">
        <intl :locale="'ru'">
            <div class="request-management">
                <CRow class="table-actions-row">
                    <v-col sm="12" cols="12" lg="4" class="pl-0">
                        <breadcrumbs :items="crumbs" />
                    </v-col>
                    <v-col
                        sm="12"
                        md="12"
                        lg="8"
                        class="action-btns d-flex justify-end align-center flex-column flex-md-row"
                    ></v-col>
                </CRow>
                <Grid
                    ref="grid"
                    class="request"
                    :resizable="true"
                    :sortable="true"
                    :filterable="true"
                    :pageable="gridPageable"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    :detail="detailTemplate"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <CButton
                                    @click="
                                        currentItem = dataItem;
                                        showEntityModal = true;
                                    "
                                    class="actions-btn btn-square btn-info"
                                >
                                    <i class="la la-pencil"></i>
                                    Изменить статус
                                </CButton>
                            </div>
                        </td>
                    </template>
                    <template v-slot:additional_services_filter>
                        <boolean-filter
                            @change="
                                selectFilter(
                                    'is_additional_services',
                                    $event.value.value
                                )
                            "
                        />
                    </template>
                    <template
                        v-slot:is_additional_services="{ props: { dataItem } }"
                    >
                        <td>
                            <span
                                class="m-badge m-badge--wide m-badge--wide"
                                :class="{
                                    'm-badge--success':
                                        dataItem.is_additional_services,
                                    'm-badge--danger':
                                        !dataItem.is_additional_services,
                                }"
                            >
                                {{
                                    dataItem.is_additional_services
                                        ? 'ДА'
                                        : 'НЕТ'
                                }}
                            </span>
                        </td>
                    </template>
                    <template v-slot:price="{ props: { dataItem } }">
                        <td>
                            {{ dataItem.price | fractionSizeSpace }}
                        </td>
                    </template>
                    <template v-slot:full_cost="{ props: { dataItem } }">
                        <td>
                            {{ dataItem.full_cost | fractionSizeSpace }}
                        </td>
                    </template>
                    <template v-slot:status_filter>
                        <boolean-filter
                            @change="selectFilter('status', $event.value.value)"
                        />
                    </template>
                    <template v-slot:status="{ props: { dataItem } }">
                        <td>
                            {{ dataItem.status_name }}
                        </td>
                    </template>
                    <template v-slot:detail="{ props: { dataItem } }">
                        <div class="detail-wrapper request-detail">
                            <v-tabs v-model="tab">
                                <v-tab
                                    key="additionalServices"
                                    href="#additionalServices"
                                    >Дополнительные услуги</v-tab
                                >
                                <v-tab key="client" href="#client"
                                    >Клиент</v-tab
                                >
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                                <v-tab-item
                                    key="additionalServices"
                                    id="additionalServices"
                                >
                                    <div class="py-5 px-5">
                                        <table
                                            class="table table-striped m-table"
                                        >
                                            <thead>
                                                <tr>
                                                    <td
                                                        style="width: 15px"
                                                    ></td>
                                                    <td style="width: 300px">
                                                        Название дополнительной
                                                        услуги
                                                    </td>
                                                    <td>
                                                        Стоимость дополнительной
                                                        услуги
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="additionalService in dataItem.additional_services"
                                                    :key="additionalService.idx"
                                                >
                                                    <td>
                                                        {{
                                                            additionalService.idx
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            additionalService.name
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            additionalService.cost
                                                        }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </v-tab-item>
                                <v-tab-item key="client" id="client">
                                    <div class="py-5 px-5">
                                        <table
                                            class="table table-striped m-table"
                                        >
                                            <tr>
                                                <td style="width: 300px">
                                                    Наименование дилера:
                                                </td>
                                                <td>
                                                    <p
                                                        v-html="
                                                            dataItem.dealer_name
                                                        "
                                                    ></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>КССС дилера:</td>
                                                <td>
                                                    <p
                                                        v-html="
                                                            dataItem.unique_id
                                                        "
                                                    ></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Номер договора агента:</td>
                                                <td>
                                                    <p
                                                        v-html="
                                                            dataItem.contract_agency_number
                                                        "
                                                    ></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Дата договора агента:</td>
                                                <td>
                                                    {{
                                                        dataItem.contract_agency_date
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>ФИО контактного лица:</td>
                                                <td>
                                                    <p
                                                        v-html="
                                                            dataItem.contact_person_name
                                                        "
                                                    ></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Должность контактного лица:
                                                </td>
                                                <td>
                                                    <p
                                                        v-html="
                                                            dataItem.contact_person_position
                                                        "
                                                    ></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Телефон контактного лица:
                                                </td>
                                                <td>
                                                    <p
                                                        v-html="
                                                            dataItem.contact_person_phone
                                                        "
                                                    ></p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </v-tab-item>
                            </v-tabs-items>
                        </div>
                    </template>
                </Grid>
                <change-status-modal
                    order-type="dealer"
                    v-if="showEntityModal"
                    :current-item="currentItem"
                    :show-dialog="showEntityModal"
                    @click:outside="showEntityModal = false"
                    @stored="entityStored($event)"
                    @close="showEntityModal = false"
                ></change-status-modal>
            </div>
        </intl>
    </localization>
</template>

<script>
    import api from '@/api';
    import { mapActions, mapGetters } from 'vuex';
    import ChangeOrderStatusModal from '@/views/content/agent-user/modals/ChangeEducationStatusModal';
    import { Grid } from '@progress/kendo-vue-grid';
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl';
    import '@/views/grid_elements/translations';
    import TableMixin from '@/views/grid_elements/mixins/TableMixin';
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin';
    import Breadcrumbs from '@/views/components/breadcrumbs';
    import ActionsDropdown from '@/views/grid_elements/ActionsDropdown';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import BooleanFilter from '@/views/components/BooleanFilter';
    import { DatePicker } from '@progress/kendo-vue-dateinputs';
    import { showConfirm } from '@/lib/swal2';
    import { showMessage } from '@/lib/toasted';
    import { toDataSourceRequestString } from '@progress/kendo-data-query';

    export default {
        name: 'request-management',
        components: {
            localization: LocalizationProvider,
            intl: IntlProvider,
            Grid: Grid,
            breadcrumbs: Breadcrumbs,
            ActionsDropdown,
            dropdownlist: DropDownList,
            BooleanFilter,
            datepicker: DatePicker,
            'change-status-modal': ChangeOrderStatusModal,
        },
        mixins: [TableMixin, ResizableTableMixin],
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                total: 7,
                tab: '',
                gridPageable: { pageSizes: true },
                detailTemplate: 'detail',
                expandedItems: [],
                columns: [
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        minWidth: 200,
                        width: '200px',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'number',
                        title: 'Номер заказа',
                        type: 'text',
                        filter: 'text',
                        minWidth: 160,
                        width: '160px',
                    },
                    {
                        field: 'created_at',
                        title: 'Дата создания',
                        type: 'date',
                        filter: 'date',
                        format: '{0:d}',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'dealer_name',
                        title: 'Дилер',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'unique_id',
                        title: 'КССС',
                        type: 'text',
                        filter: 'text',
                        minWidth: 160,
                        width: '160px',
                    },
                    {
                        field: 'start_at',
                        title: 'Дата обучения',
                        type: 'date',
                        filter: 'date',
                        format: '{0:d}',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'title',
                        title: 'Название курса',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'user_count',
                        title: 'Количество участников',
                        type: 'numeric',
                        filter: 'numeric',
                        minWidth: 200,
                        width: '200px',
                    },
                    {
                        field: 'format',
                        title: 'Онлайн/Офлайн',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'city',
                        title: 'Город проведения',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'price',
                        title: 'Сумма заказа',
                        type: 'numeric',
                        filter: 'numeric',
                        minWidth: 200,
                        width: '200px',
                    },
                    {
                        field: 'status',
                        title: 'Статус',
                        cell: 'status',
                        filterCell: 'status_filter',
                        minWidth: 160,
                        width: '160px',
                    },
                    {
                        field: 'curator_from_dealer_name',
                        title: 'ФИО',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'curator_from_dealer_phone_number',
                        title: 'Номер телефона',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'curator_from_dealer_email',
                        title: 'E-mail',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                ],
            };
        },
        mounted() {
            if (this.isCurrentUserAdministrator) {
                this.fetchAgencyListShort();
            }

            this.fetchDealerListShort();
            this.getData();
        },
        computed: {
            ...mapGetters('dealer', ['dealersListShort']),
            dealerFilter() {
                return [
                    {
                        id: null,
                        name: 'Все',
                    },
                    ...this.dealersListShort,
                ];
            },
        },
        methods: {
            fetchData: api.agent_organizer.getEducationOrdersManagement,
            ...mapActions('dealer', ['fetchDealerListShort']),
            selectDealer({ value }) {
                if (value.id) {
                    this.setFilter('dealer_id', 'eq', value.id);
                } else {
                    this.removeFilter('dealer_id');
                }

                this.updateTable(true);
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ];
            },
        },
    };
</script>

<style scoped lang="scss">
    .page-container {
        min-height: 100%;
    }
    .c-body
        .c-main
        .k-grid
        .k-master-row
        td
        .actionsColumn
        .actions-btn.btn::after {
        display: none;
    }
</style>
