<template>
  <v-dialog width="800" id="product-image-picker-modal" class="product-attachment-modal" :value="showDialog">
    <v-card>
      <v-card-title class="headline">
        <span><i class="fa fa-edit"></i> Изменение статуса заказа</span>
      </v-card-title>

      <v-card-text>
        <form class="m-form m-form--state">
          <div>
            <v-select
                v-model="status"
                :items="availableStatuses"
                item-value="key"
                item-text="name"
                :label="availableStatuses.length != 0 ? 'Изменить статус на *' : 'Уже установлен финальный статус, редактирование невозможно'"
                hide-details="auto"
                required
                :disabled="availableStatuses.length != 0 ? false : true"
            ></v-select>
          </div>
        </form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
        <CButton :disabled="disabled || !status" @click="submit" color="success"><i class="fa fa-arrow-right"></i> <span>Изменить статус</span></CButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { serialize } from 'object-to-formdata';
import api from "@/api";
import {showMessage} from "@/lib/toasted";
import EntityModalMixin from "@/views/grid_elements/mixins/EntityModalMixin";
import {mapGetters} from "vuex";
import getAvailableStatusesToChangeEducation from "@/api/entities/orders";
import changeEducationOrderStatus from "@/api/entities/orders";

export default {
  name: "ChangeOrderStatusModal",
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    orderType: {
      type: String,
      default: 'dealer'
    }
  },
  mixins: [EntityModalMixin],
  async mounted() {
    await this.fetchStatuses();
  },
  data() {
    return {
      status: null,
      statuses: [],
      disabled: false
    }
  },
  methods: {
    async fetchStatuses() {
      try {
        const {
          data: {data: {statuses}}
        } = await api.orders.getAvailableStatusesToChangeEducation(this.currentItem.id);
        this.statuses = statuses;
      } catch (e) {
        console.log(e);
      }
    },
    async submit() {
      this.disabled = true
      const method = api.orders.changeEducationOrderStatus;
      const {data: {status, message}} = await method(this.currentItem.id, this.status);

      showMessage(status, message);

      if (status === 'ok') {
        this.$emit('stored');
      }
      this.disabled = false
    }
  },
  computed: {
    ...mapGetters('orders', ['getEducationOrderStatusNameByKey']),
    availableStatuses() {
      const that = this;

      return this.statuses.map((status) => ({
        key: status,
        name: that.getEducationOrderStatusNameByKey(status)
      }))
    }
  }
}
</script>

<style scoped>

</style>
