var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('localization',{attrs:{"language":'ru-RU'}},[_c('intl',{attrs:{"locale":'ru'}},[_c('div',{staticClass:"request-management"},[_c('CRow',{staticClass:"table-actions-row"},[_c('v-col',{staticClass:"pl-0",attrs:{"sm":"12","cols":"12","lg":"4"}},[_c('breadcrumbs',{attrs:{"items":_vm.crumbs}})],1),_c('v-col',{staticClass:"action-btns d-flex justify-end align-center flex-column flex-md-row",attrs:{"sm":"12","md":"12","lg":"8"}})],1),_c('Grid',{ref:"grid",staticClass:"request",attrs:{"resizable":true,"sortable":true,"filterable":true,"pageable":_vm.gridPageable,"filter":_vm.filter,"take":_vm.take,"skip":_vm.skip,"sort":_vm.sort,"page-size":_vm.pageSize,"total":_vm.total,"data-items":_vm.dataResult,"columns":_vm.columns,"expand-field":'expanded',"detail":_vm.detailTemplate},on:{"datastatechange":_vm.dataStateChange,"expandchange":_vm.expandChange,"sortchange":_vm.sortChange},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var dataItem = ref.props.dataItem;
return [_c('td',[_c('div',{staticClass:"actionsColumn"},[_c('CButton',{staticClass:"actions-btn btn-square btn-info",on:{"click":function($event){_vm.currentItem = dataItem;
                                    _vm.showEntityModal = true;}}},[_c('i',{staticClass:"la la-pencil"}),_vm._v(" Изменить статус ")])],1)])]}},{key:"additional_services_filter",fn:function(){return [_c('boolean-filter',{on:{"change":function($event){return _vm.selectFilter(
                                'is_additional_services',
                                $event.value.value
                            )}}})]},proxy:true},{key:"is_additional_services",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('td',[_c('span',{staticClass:"m-badge m-badge--wide m-badge--wide",class:{
                                'm-badge--success':
                                    dataItem.is_additional_services,
                                'm-badge--danger':
                                    !dataItem.is_additional_services,
                            }},[_vm._v(" "+_vm._s(dataItem.is_additional_services ? 'ДА' : 'НЕТ')+" ")])])]}},{key:"price",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("fractionSizeSpace")(dataItem.price))+" ")])]}},{key:"full_cost",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("fractionSizeSpace")(dataItem.full_cost))+" ")])]}},{key:"status_filter",fn:function(){return [_c('boolean-filter',{on:{"change":function($event){return _vm.selectFilter('status', $event.value.value)}}})]},proxy:true},{key:"status",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(dataItem.status_name)+" ")])]}},{key:"detail",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('div',{staticClass:"detail-wrapper request-detail"},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"additionalServices",attrs:{"href":"#additionalServices"}},[_vm._v("Дополнительные услуги")]),_c('v-tab',{key:"client",attrs:{"href":"#client"}},[_vm._v("Клиент")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"additionalServices",attrs:{"id":"additionalServices"}},[_c('div',{staticClass:"py-5 px-5"},[_c('table',{staticClass:"table table-striped m-table"},[_c('thead',[_c('tr',[_c('td',{staticStyle:{"width":"15px"}}),_c('td',{staticStyle:{"width":"300px"}},[_vm._v(" Название дополнительной услуги ")]),_c('td',[_vm._v(" Стоимость дополнительной услуги ")])])]),_c('tbody',_vm._l((dataItem.additional_services),function(additionalService){return _c('tr',{key:additionalService.idx},[_c('td',[_vm._v(" "+_vm._s(additionalService.idx)+" ")]),_c('td',[_vm._v(" "+_vm._s(additionalService.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(additionalService.cost)+" ")])])}),0)])])]),_c('v-tab-item',{key:"client",attrs:{"id":"client"}},[_c('div',{staticClass:"py-5 px-5"},[_c('table',{staticClass:"table table-striped m-table"},[_c('tr',[_c('td',{staticStyle:{"width":"300px"}},[_vm._v(" Наименование дилера: ")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(
                                                        dataItem.dealer_name
                                                    )}})])]),_c('tr',[_c('td',[_vm._v("КССС дилера:")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(
                                                        dataItem.unique_id
                                                    )}})])]),_c('tr',[_c('td',[_vm._v("Номер договора агента:")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(
                                                        dataItem.contract_agency_number
                                                    )}})])]),_c('tr',[_c('td',[_vm._v("Дата договора агента:")]),_c('td',[_vm._v(" "+_vm._s(dataItem.contract_agency_date)+" ")])]),_c('tr',[_c('td',[_vm._v("ФИО контактного лица:")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(
                                                        dataItem.contact_person_name
                                                    )}})])]),_c('tr',[_c('td',[_vm._v(" Должность контактного лица: ")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(
                                                        dataItem.contact_person_position
                                                    )}})])]),_c('tr',[_c('td',[_vm._v(" Телефон контактного лица: ")]),_c('td',[_c('p',{domProps:{"innerHTML":_vm._s(
                                                        dataItem.contact_person_phone
                                                    )}})])])])])])],1)],1)]}}])}),(_vm.showEntityModal)?_c('change-status-modal',{attrs:{"order-type":"dealer","current-item":_vm.currentItem,"show-dialog":_vm.showEntityModal},on:{"click:outside":function($event){_vm.showEntityModal = false},"stored":function($event){return _vm.entityStored($event)},"close":function($event){_vm.showEntityModal = false}}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }